<template>
	<div class="authorized-user">
		<v-row no-gutters dense class="d-flex" style="height:inherit">
			<v-col cols="12" class="col-md-12 pt-8 my-pr">
				<v-card class="d-flex flex-column my-card">
					<v-row no-gutters dense class="d-flex align-start flex-grow-0 pb-sm-10">
						<v-col cols="12">
							<p class="heading-sf20">Manage authorized user accounts</p>
						</v-col>
					</v-row>
					<v-row no-gutters dense class="d-flex flex-column flex-grow-0">
						<v-row class="alternet d-flex flex-column ma-0">
							<transition-group name="add-remove">
								<v-row
									v-for="user in authorizeusers"
									v-bind:key="user.email"
									class="ma-0 d-flex flex-column add-remove-item"
								>
									<div class="user-box box-white d-flex align-center justify-sm-end PR-32">
										<span class="mr-auto">{{ user.email }}</span>
										<span v-if="$store.state.setting.distributor.role === 'A'" class="icon">
											<img
												:src="$store.state.icons.icons['Dustbin']"
												@click="deleteUser(user)"
												class="H-18"
												alt
											/>
										</span>
									</div>
								</v-row>
							</transition-group>
						</v-row>

						<v-form v-model="valid" ref="form" lazy-validation>
							<v-row no-gutters dense v-if="adduser" class="pt-4">
								<div class="user-box d-flex align-center justify-end PR-32">
									<span class="mr-auto white-text-box">
										<v-text-field
											single-line
											solo
											:hide-details="Show"
											autocomplete="false"
											height="48"
											color="#CACEDA"
											type="text"
											placeholder="Enter user email address"
											:rules="emailRules"
											v-model="email"
											required
										></v-text-field>
									</span>

									<span class="text-button">
										<v-btn class="text-btn pr-0" text :disabled="!valid" @click="addUser">Confirm</v-btn>
									</span>
								</div>
							</v-row>
						</v-form>
					</v-row>
					<v-row no-gutters dense class="d-flex align-sm-stretch align-content-end">
						<v-col
							v-if="error_message != ''"
							class="d-flex align-center mt-auto p-0 custom-error"
							cols="12"
						>{{ this.error_message }}</v-col>
						<v-col cols="12" sm="6" class="d-flex justify-sm-start justify-center mt-auto">
							<v-btn
								v-if="$store.state.setting.distributor.role === 'A'"
								class="my-outline-btn mr-sm-auto mb-sm-0 mb-4"
								depressed
								outlined
								v-on:click="adduser = !adduser"
							>Add user</v-btn>
						</v-col>
						<v-col cols="12" sm="6" class="d-flex justify-end mt-auto">
							<v-btn
								class="my-auto-btn my_elevation"
								id="my_elevation"
								:disabled="!valid || $store.state.setting.distributor.role === 'AU'"
								depressed
							>Save changes</v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import {
	addAuthorizeUser,
	removeAuthorizeUser
} from "../../services/settingservice";
require("../../scss/authorize.scss");
export default {
	data() {
		return {
			active_tab: false,
			show: true,
			valid: true,
			error_message: "",
			adduser: false,
			authorizeusers: [],
			email: "",
			emailRules: [
				v => !!v || "E-mail is required",
				v => /.+@.+/.test(v) || "E-mail must be valid"
			]
		};
	},

	created() {
		this.$store.watch(
			state => {
				return state.auth.error;
			},
			() => {
				if (this.active_tab === true) {
					this.error_message = this.$store.state.auth.error;
				}
			},
			{ deep: true }
		);

		this.$store.watch(
			state => {
				return state.setting.authorize_user, state.setting.setting_tab;
			},
			() => {
				if (
					this.$store.state.setting.setting_tab.tab ===
					"authorize_user"
				) {
					let data = this.$store.state.setting.authorize_user
						.authorize_user;
					this.myAuthorize(data);
				}
			},
			{ deep: true }
		);
	},

	async mounted() {
		let data = this.$store.state.setting.authorize_user.authorize_user;
		this.myAuthorize(data);
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true);
		}
	},

	methods: {
		myAuthorize(data) {
			this.authorizeusers = [];
			data.forEach(user => {
				let obj = {
					user_id: user.user_id,
					email: user.email
				};

				this.authorizeusers.push(obj);
			});
		},

		async addUser() {
			if (this.$refs.form.validate()) {
				this.error_message = "";
				this.$store.commit("errorMessage", "");
				this.active_tab = true;
				this.adduser = true;
				let data = await addAuthorizeUser({
					email: this.email
				});

				if (data.data) {
					let obj = {
						user_id: data.data.id,
						email: data.data.email
					};

					this.authorizeusers.push(obj);
					this.$store.state.setting.authorize_user.authorize_user = JSON.parse(
						JSON.stringify(this.authorizeusers)
					);
					this.email = "";
					this.active_tab = false;
					this.adduser = false;
				} else {
					this.error_message = this.$store.state.auth.error;
				}
			}
		},

		async deleteUser(user) {
			let data = await removeAuthorizeUser(user.user_id, this.company_id);

			if (data.data) {
				this.authorizeusers.splice(
					this.authorizeusers.indexOf(user),
					1
				);
				this.$store.state.setting.authorize_user.authorize_user.splice(
					this.authorizeusers.indexOf(user),
					1
				);
				this.active_tab = false;
			} else {
				this.error_message = this.$store.state.auth.error;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.authorized-user {
	height: 100%;
}
</style>